import dynamic from "next/dynamic";
import EditorJSContent from "../../editor-js-content";
import {
  HorizontalCardListItemWrapper,
  Image,
  InformationWrapper,
  Title,
  BodyWrapper,
  LinkWrapper,
} from "./styles";

const Link = dynamic(() => import("../link/link"));

export const HorizontalCardListItem = ({ title, body, cta, image }) => {
  return (
    <HorizontalCardListItemWrapper>
      {image && <Image media={image} />}
      <InformationWrapper>
        {title && <Title>{title}</Title>}
        {body && (
          <BodyWrapper>
            <EditorJSContent content={body} />
          </BodyWrapper>
        )}
        {cta && (
          <LinkWrapper>
            <Link type="buttonPrimary" {...cta} />
          </LinkWrapper>
        )}
      </InformationWrapper>
    </HorizontalCardListItemWrapper>
  );
};

export default HorizontalCardListItem;
